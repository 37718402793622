.Products {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Products_header {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.4),
      rgba(255, 255, 255, 1)
    ),
    url("../../images/products/products2.jpeg");
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  filter: hue-rotate(4rad);
  margin: 0;
  padding: 100px 0;
}

.Products_title {
  font-size: 2.5rem;
  font-weight: 600;
  padding: 0;
}

.Products_container {
  width: 95%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 50px;
}

.Products_side-container {
  max-width: 16%;
  min-width: 210px;
}

.Products_categories-container {
  border: 1px solid rgba(200, 200, 200, 0.6);
  background-color: white;
}

.Products_categories-title {
  font-size: 1.2rem;
  font-weight: 600;
  border-bottom: 1px solid rgba(200, 200, 200, 0.6);
  padding: 15px;
}

.Products_categories-list {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 15px;
}

.Products_categories_list-item {
  font-size: 1rem;
  cursor: pointer;
}

.Products_categories_list-item:hover {
  text-decoration: underline;
}

.Products_topsales-container {
  position: relative;
  /* max-height: 800px; */
  /* height: 80vh; */
  border: 1px solid rgba(200, 200, 200, 0.6);
  background-color: white;
  margin-top: 20px;
  /* overflow: hidden; */
}

.Products_pagination {
  margin: 50px;
}

.Products_note {
  width: 80%;
  text-align: center;
  font-size: 1.2rem;
  font-weight: 600;
  padding: 0 0 40px;
}

.Products_link {
  font-size: 1.2rem;
  font-weight: 600;
  text-decoration: underline;
  color: blue;
}

@media only screen and (max-width: 760px) {
  .Products_container {
    gap: 20px;
  }

  .Products_side-container {
    max-width: 15%;
    min-width: 180px;
  }

  .Products_topsales-container {
    /* max-height: 200px; */
    border: 1px solid rgba(200, 200, 200, 0.6);
    background-color: white;
    margin-top: 30px;
    overflow: hidden;
  }
}

@media only screen and (max-width: 600px) {
  .Products_header {
    padding: 50px 0;
  }

  .Products_container {
    flex-direction: column;
    gap: 20px;
  }

  .Products_side-container {
    max-width: 100%;
  }

  .Products_categories-title {
    font-size: 1.3rem;
    padding: 10px;
  }

  .Products_categories-list {
    gap: 10px;
    padding: 10px;
  }

  .Products_topsales-container {
    margin-top: 20px;
    height: fit-content;
    padding: 10px 0;
  }
}
