.Products-page {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  padding: 50px 0 0;
  background-image: linear-gradient(
    rgba(200, 200, 200, 0.1),
    rgba(255, 255, 255, 1)
  );
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.Products-page-title {
  text-align: center;
  font-size: 2.5rem;
  font-weight: 600;
  margin: 0 0 20px;
  padding: 0;
}

.Products-page-subtitle {
  width: 80%;
  font-size: 20px;
  text-align: center;
  margin: 0 0 60px;
}

.Products-container {
  max-width: 1200px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
  gap: 0;
  margin: 0 0 40px;
  padding: 0;
}

.Products-categories {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
}

#aa {
  grid-area: aa;
}
#bb {
  grid-area: bb;
}
#cc {
  grid-area: cc;
}
#dd {
  grid-area: dd;
}
#ee {
  grid-area: ee;
}
#ff {
  grid-area: ff;
}

.Products-categories-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: auto;
  row-gap: 20px;
  column-gap: 20px;
  grid-template-areas:
    "aa aa bb cc"
    "dd ee ee ff";
  padding: 0 20px;
}

.Products-categories-item {
  max-height: 260px;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  border: none;
  margin: 0;
  padding: 0;
}

.Products-categories-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.Products-categories-text {
  width: 100%;
  height: 100%;
  bottom: 0;
  left: 0;
  position: absolute;
  background: linear-gradient(
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0),
    rgba(0, 0, 0, 0.1),
    rgba(0, 0, 0, 0.5)
  );
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
  opacity: 0.8;
  transition: 0.5s;
  color: white;
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 4rem;
}

.Products-categories-text:hover {
  opacity: 1;
}

.Products-new-arrival {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
}

.Products-new-arrival-container {
  display: block;
}

.Products-button {
  font-size: 1rem;
  font-weight: 600;
  background-color: #3d73aa;
  color: white;
  border: none;
  border-radius: 10px;
  line-height: 1;
  padding: 15px 30px;
  cursor: pointer;
  transition: all 0.3s;
  margin: 50px auto 0;
}

.Products-button:hover {
  color: white;
  background-color: #333333;
}

@media only screen and (max-width: 760px) {
  .Products-categories-container {
    row-gap: 10px;
    column-gap: 10px;
  }

  .Products-categories-item {
    max-height: 200px;
  }
}

@media only screen and (max-width: 500px) {
  .Products-page-title {
    font-size: 2rem;
    font-weight: 600;
  }

  .Products-page-subtitle {
    width: 95%;
    font-size: 1.1rem;
    margin: 0 0 40px;
  }

  .Products-categories-container {
    row-gap: 5px;
    column-gap: 5px;
    padding: 0 10px;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas:
      "aa aa bb"
      "cc dd dd"
      "ee ee ff";
  }

  .Products-categories-item {
    max-height: 110px;
  }

  .Products-categories-text {
    opacity: 1;
    line-height: 3rem;
  }
}
