.About-container {
  position: relative;
  width: 90%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  gap: 40px;
  margin: 0 auto;
  padding: 50px 0;
  overflow: hidden;
}

.About-title {
  font-size: 2.5rem;
  font-weight: 600;
  margin: 0;
  padding: 0;
}

.About-subtitle {
  font-size: 1.1rem;
  line-height: 2rem;
  margin: 10px 0 20px;
}

.About_video {
  width: 200%;
  aspect-ratio: 16/9;
  border: none !important;
}

.About-button {
  font-size: 1rem;
  font-weight: 600;
  background-color: #3d73aa;
  color: white;
  border: none;
  border-radius: 10px;
  line-height: 1;
  padding: 15px 30px;
  cursor: pointer;
  transition: all 0.3s;
}

.About-button:hover {
  color: white;
  background-color: #333333;
}

@media only screen and (max-width: 960px) {
  .About-container {
    width: 95%;
    padding: 30px 0;
  }
}

@media only screen and (max-width: 760px) {
  .About-container {
    width: 90%;
    padding: 30px 0;
    flex-direction: column;
    justify-content: center;
  }

  .About-section1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .About_video {
    width: 70%;
  }
}

@media only screen and (max-width: 500px) {
  .About-title {
    font-size: 2rem;
    text-align: center;
  }

  .About-subtitle {
    font-size: 1.1rem;
    line-height: 1.4rem;
    margin: 10px 0 20px;
    text-align: center;
  }

  .About_video {
    width: 100%;
  }

  .About-button {
    font-size: 1rem;
    margin: 0 auto;
    padding: 10px 20px;
  }
}
