.Drawer {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: space-between; */
  gap: 30px;
  max-width: 400px;
  width: 25vw;
  height: 100vh;
  background-color: rgb(255, 255, 255);
  padding: 25px 10px;
  overflow: hidden;
}

.Drawer_items-container {
  max-height: 75vh;
  overflow-y: auto;
}

.Drawer_item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  border: 1px solid rgba(128, 128, 128, 0.2);
  border-radius: 5px;
  background-color: white;
  margin: 5px;
  padding: 5px;
  cursor: pointer;
}

.Drawer_item-image {
  width: 25%;
  cursor: pointer;
}

.Drawer_item-title {
  overflow: hidden;
  font-size: 0.9rem;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin: 10px 0;
  cursor: pointer;
  transition: all 0.3s;
}

.Drawer_item-title:hover {
  text-decoration: underline;
}

.Drawer_item-mpq {
  font-size: 0.9rem;
  font-weight: 600;
}

.Drawer_button {
  width: 50%;
  font-size: 1rem;
  background-color: #3d73aa;
  padding: 15px 30px;
  color: white;
  text-transform: uppercase;
  border: none;
  border-radius: 10px;
  line-height: 1;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  cursor: pointer;
  transition: all 0.3s;
}

.Drawer_button:hover {
  background-color: #333333;
}

.Drawer_form-container {
  display: flex;
  flex-direction: column;
  gap: 25px;
  padding: 0 10px;
}

.Drawer_form-title {
  font-size: 2rem;
  margin: 0;
  padding: 0;
}

.Drawer_form-error {
  font-size: 0.9rem;
  font-weight: 600;
  color: red;
  margin: -10px 0;
  padding: 0;
}

@media only screen and (max-width: 1250px) {
  .Drawer {
    width: 30vw;
    padding: 25px 10px;
  }
}

@media only screen and (max-width: 960px) {
  .Drawer {
    gap: 20px;
    width: 40vw;
    padding: 20px 10px;
  }
}

@media only screen and (max-width: 600px) {
  .Drawer {
    gap: 20px;
    width: 80vw;
  }

  .Drawer_items-container {
    max-height: 70vh;
  }
}
