@import url(./vendor/normalize.css);

* {
  list-style: none;
  text-decoration: none;
  color: #323232;
  font-size: 16px;
  /* font-family: "El Messiri", sans-serif; */
  font-family: "Raleway", sans-serif;
  font-weight: 500;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  scroll-padding-top: 100px;
}

img {
  user-select: none;
}

@media (max-width: 900px) {
  * {
    font-size: calc(14px);
  }
}
