.Contact {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
}

.Contact_header {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.1),
      rgba(255, 255, 255, 1)
    ),
    url("../../images/contact/contact-us2.jpg");
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 100px 0;
}

.Contact_header-title {
  width: fit-content;
  font-size: 2.5rem;
  font-weight: 600;
  padding: 0;
}

.Contact_header-subtitle {
  width: fit-content;
  font-size: 1.4rem;
  margin: -20px 0 0;
  padding: 0;
}

.Contact_contact-container {
  display: flex;
  justify-content: space-evenly;
  gap: 20px;
  background-image: linear-gradient(
      rgba(255, 255, 255, 1),
      rgba(255, 255, 255, 0.5)
    ),
    url("../../images/contact/contact-us.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 0 50px 100px;
}

.Contact_contact-box {
  display: flex;
  width: 30%;
  flex-direction: column;
  gap: 20px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 1px 1px 22px 1px rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 1px 1px 22px 1px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 1px 1px 22px 1px rgba(0, 0, 0, 0.15);
  padding: 30px;
}

.Contact_contact-box_title {
  font-size: 2rem;
  color: black;
}

.Contact_contact-box_button {
  width: 40%;
  align-self: center;
  font-size: 16px;
}

.Contact_contact-secondary {
  max-width: 40%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 30px;
}

.Contact_contact-box_title-secondary {
  font-size: 2rem;
  font-weight: 600;
}

.footer_text {
  font-size: 1.3rem;
  font-weight: 500;
  line-height: 2rem;
  margin: 0 0 20px;
}

.footer__list {
  width: 80%;
  height: 50px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  gap: 50px;
  list-style: none;
  margin-top: 50px;
}

.footer__list-item {
  text-decoration: none;
  border-radius: 40px;
}

.footer__list-image {
  display: flex;
  width: 40px;
  background-color: #3d73aa;
  border-radius: 100px;
  margin: 0;
  padding: 5px;
  transition: all 0.2s;
}

.footer__list-image:hover {
  margin-bottom: 5px;
}

.footer_social-container {
  text-align: left;
  padding: 20px 0;
}

.material-icon {
  width: 45px;
  margin: 0 8px;
  transition: all 0.2s ease;
}

.material-icon:hover {
  transform: translateY(-5px);
}

@media only screen and (max-width: 1250px) {
  .Contact_contact-container {
    gap: 20px;
    padding: 50px 10px 100px;
  }
}

@media only screen and (max-width: 960px) {
  .Contact_contact-box {
    width: 45%;
    gap: 20px;
    padding: 20px;
  }

  .Contact_contact-box_title {
    font-size: 1.8rem;
  }

  .Contact_contact-box_button {
    width: 50%;
  }

  .Contact_contact-secondary {
    max-width: 40%;
    gap: 10px;
    padding: 20px;
  }

  .Contact_contact-box_title-secondary {
    font-size: 1.8rem;
  }

  .footer_text {
    font-size: 1rem;
    line-height: 1.5rem;
    margin: 0 0 10px;
  }

  .material-icon {
    width: 35px;
    margin: 0 5px;
  }
}

@media only screen and (max-width: 780px) {
  .Contact_header {
    padding: 60px 0 40px;
  }

  .Contact_header-subtitle {
    width: 90%;
    text-align: center;
    font-size: 1.2rem;
    margin: 15px 0 20px;
    padding: 0;
  }

  .Contact_contact-container {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    gap: 15px;
    padding: 0 0 50px;
  }

  .Contact_contact-box {
    width: 60%;
    padding: 20px;
  }

  .Contact_contact-box_title {
    font-size: 1.8rem;
  }

  .Contact_contact-box_button {
    width: 50%;
  }

  .Contact_contact-secondary {
    text-align: center;
    max-width: 80%;
    gap: 10px;
    padding: 0 20px;
  }
}

@media only screen and (max-width: 500px) {
  .Contact_header {
    padding: 50px 0;
  }

  .Contact_header-subtitle {
    margin: -15px 0 0;
  }

  .Contact_contact-container {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    gap: 15px;
    padding: 0 0 50px;
  }

  .Contact_contact-box {
    width: 80%;
    padding: 20px;
  }

  .Contact_contact-box_button {
    width: 50%;
  }

  .Contact_contact-secondary {
    text-align: center;
    max-width: 100%;
    gap: 10px;
    padding: 0 20px;
  }
}
