.Contact_container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  background-image: linear-gradient(
      rgba(255, 255, 255, 1),
      rgba(255, 255, 255, 0.5)
    ),
    url("../../../images/contact/contact-home.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  gap: 20px;
  padding: 100px 50px;
}

.Contact_box {
  display: flex;
  width: 30%;
  flex-direction: column;
  gap: 20px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 5px 5px 15px 1px rgba(0, 0, 0, 0.4);
  -webkit-box-shadow: 5px 5px 15px 1px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 5px 5px 15px 1px rgba(0, 0, 0, 0.4);
  padding: 30px;
}

.Contact_box_title {
  font-size: 32px;
  color: black;
}

.Contact_box_button {
  width: 40%;
  align-self: center;
  font-size: 16px;
}

.Contact_secondary {
  max-width: 40%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 30px;
}

.Contact_box_title-secondary {
  font-size: 38px;
  font-weight: 600;
}

.footer__text {
  font-size: 22px;
  font-weight: 600;
  line-height: 1.5rem;
  margin: 0 0 20px;
}

.footer__list {
  width: 80%;
  height: 50px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  gap: 50px;
  list-style: none;
  margin-top: 50px;
}

.footer__list-item {
  text-decoration: none;
  border-radius: 40px;
}

.footer__list-image {
  display: flex;
  width: 45px;
  background-color: #3d73aa;
  border-radius: 100px;
  margin: 0;
  padding: 5px;
  transition: all 0.2s;
}

.footer__list-image:hover {
  margin-bottom: 5px;
}

@media only screen and (max-width: 1250px) {
  .Contact_container {
    gap: 20px;
    padding: 50px 10px 100px;
  }
}

@media only screen and (max-width: 960px) {
  .Contact_box {
    width: 45%;
    gap: 20px;
    padding: 20px;
  }
  .Contact_box_title {
    font-size: 1.8rem;
  }
  .Contact_box_title-secondary {
    font-size: 1.8rem;
  }
  .footer__text {
    font-size: 1.1rem;
    line-height: 1.5rem;
    margin: 0 0 20px;
  }
}

@media only screen and (max-width: 780px) {
  .Contact_container {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0;
    padding: 10px 10px 100px;
  }
  .Contact_box {
    width: 55%;
    gap: 20px;
    padding: 20px;
  }
  .Contact_box_title {
    font-size: 1.8rem;
    text-align: center;
  }
  .Contact_secondary {
    max-width: 80%;
    align-items: center;
    justify-content: center;
    gap: 20px;
    padding: 30px;
  }
  .Contact_box_title-secondary {
    font-size: 1.8rem;
  }
  .footer__text {
    text-align: center;
    font-size: 1.1rem;
    line-height: 1.5rem;
    margin: 0 0 20px;
  }
  .footer_social-container {
    text-align: center;
    padding: 5px 0 0;
  }
}

@media only screen and (max-width: 500px) {
  .Contact_container {
    padding: 0 10px 50px;
  }
  .Contact_box {
    width: 85%;
  }
  .Contact_box_title {
    font-size: 2rem;
    text-align: center;
  }
  .Contact_secondary {
    max-width: 90%;
    gap: 10px;
    padding: 25px;
  }
  .Contact_box_title-secondary {
    font-size: 2rem;
  }
  .footer_social-container {
    text-align: center;
    padding: 5px 0 0;
  }
}
