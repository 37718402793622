.hero {
  position: relative;
  width: 100%;
  margin: 0 auto 12px;
  padding: 0;
}

.Carousel-container {
  position: relative;
  width: 100%;
  max-height: 500px;
  outline: none;
}

.Carousel-image {
  width: 100%;
  height: 450px;
  background-repeat: no-repeat;
}

.Carousel-image1 {
  background-image: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0.9),
      rgba(0, 0, 0, 0)
    ),
    url("../../../images/hero/factory.jpg");
  background-position: top;
  background-size: cover;
}
.Carousel-image2 {
  background-image: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0.5),
      rgba(0, 0, 0, 0)
    ),
    url("../../../images/hero/background5.jpg");
  background-position: bottom;
  background-size: cover;
}
.Carousel-image3 {
  background-image: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0.5),
      rgba(0, 0, 0, 0)
    ),
    url("../../../images/contact/contactus2.jpg");
  background-position: center;
  background-size: cover;
}

.Carousel-content-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 5;
  text-align: center;
  margin-left: 200px;
  padding: 0;
}

.Carousel-title {
  font-family: "Raleway", sans-serif;
  font-size: 3rem;
  font-weight: 600;
  margin: 0;
  padding: 0;
}

.Carousel-text {
  font-family: "Raleway", sans-serif;
  font-size: 2rem;
  font-weight: 400;
  margin: 10px 0 55px;
}

.Carousel-button {
  font-size: 1rem;
  background-color: #3f78b1;
  color: white;
  text-transform: uppercase;
  border: none;
  border-radius: 5px;
  line-height: 1;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  cursor: pointer;
  padding: 15px 30px;
}

.Carousel-button:hover {
  background-color: #000000;
  transition: all 0.3s;
}

@media only screen and (max-width: 1250px) {
  .Carousel-content-container {
    margin-left: 130px;
  }
}

@media only screen and (max-width: 960px) {
  .Carousel-image {
    height: 400px;
  }

  .Carousel-content-container {
    right: 0;
    margin-left: 0;
    background-color: rgba(240, 248, 255, 0.5);
  }
}

@media only screen and (max-width: 500px) {
  .Carousel-image {
    height: 250px;
  }

  .Carousel-title {
    font-size: 2.5rem;
  }

  .Carousel-text {
    font-size: 1.3rem;
    margin: 10px 0 30px;
    font-weight: 600;
  }

  .Carousel-button {
    font-size: 1rem;
    padding: 10px 20px;
  }
}
