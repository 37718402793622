.About {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
}

.About_header {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 1)
    ),
    url("../../images/factory/factory1.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 100px 0;
}

.About_cover-title {
  text-align: center;
  font-size: 2.5rem;
  font-weight: 600;
  padding: 0;
}

.About_cover-subtitle {
  text-align: center;
  font-size: 1.4rem;
  margin: -20px 0 0;
  padding: 0;
}

.About_body-container {
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 20px;
  margin: 0 auto 50px;
}

.About_content-container {
  width: 50%;
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
}

.About_header-title {
  width: fit-content;
  font-size: 2rem;
  font-weight: 600;
  margin: 15px 0;
  padding: 0;
}

.About_header-subtitle {
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 500;
  color: black;
}

.About_header-image {
  width: 40%;
}

.About_body {
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin: 0 auto 50px;
}

.About_big-image {
  width: 100%;
}

.About_body-photos {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  gap: 10px;
  overflow: hidden;
}

.About_small-image {
  width: 35%;
}

@media only screen and (max-width: 1250px) {
  .About_body-container {
    width: 90%;
    gap: 20px;
    margin: 40px auto;
  }

  .About_cover-title {
    font-size: 2rem;
  }

  .About_cover-subtitle {
    font-size: 1rem;
  }

  .About_header-title {
    font-size: 2rem;
    margin: 10px 0;
  }

  .About_header-subtitle {
    font-size: 1rem;
    line-height: 1.6;
    margin-bottom: 15px;
  }
}

@media only screen and (max-width: 960px) {
  .About_body-container {
    width: 95%;
    gap: 10px;
    margin: 20px auto;
  }

  .About_cover-title {
    font-size: 2rem;
  }

  .About_cover-subtitle {
    font-size: 1rem;
  }

  .About_header-title {
    font-size: 1.6rem;
    margin: 10px 0;
  }

  .About_header-subtitle {
    font-size: 0.9rem;
    line-height: 1.6;
    margin-bottom: 15px;
  }

  .About_body {
    width: 95%;
  }
}

@media only screen and (max-width: 780px) {
  .About_cover {
    padding: 60px 0 30px;
  }

  .About_header {
    margin: 0;
  }

  .About_body-container {
    width: 90%;
    flex-direction: column;
    gap: 10px;
    margin: 15px auto;
  }

  .About_body-container:nth-of-type(odd) {
    flex-direction: column-reverse;
  }

  .About_content-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .About_content-container:nth-of-type(even) {
    flex-direction: column-reverse;
  }

  .About_header-title {
    font-size: 1.8rem;
    margin: 10px 0;
  }

  .About_header-subtitle {
    font-size: 1rem;
    line-height: 1.6;
    margin-bottom: 15px;
  }

  .About_body {
    width: 95%;
  }
}

@media only screen and (max-width: 500px) {
  .About_header {
    padding: 50px 0;
  }

  .About_cover-subtitle {
    font-size: 1.2rem;
    margin: -15px 0 0;
  }

  .About_header-image {
    width: 60%;
  }
}
