.App {
  position: relative;
  width: 100%;
  max-width: 1600px;
  margin: 0 auto;
  padding: 0;
}

.menu-items {
  position: relative;
  font-size: 18px;
  font-weight: 600;
  margin: 5px;
}

.menu-items a {
  display: block;
  font-size: inherit;
  color: inherit;
  text-decoration: none;
  font-weight: 600;
}

.menu-items button {
  display: flex;
  align-items: center;
  justify-content: center;
  color: inherit;
  font-size: inherit;
  border: none;
  background-color: transparent;
  cursor: pointer;
  width: 100%;
}

button span {
  margin-left: 3px;
}

.menu-items > a,
.menu-items button {
  font-size: 1rem;
  font-weight: 600;
  text-align: left;
  padding: 10px 15px 5px;
  color: black;
  border-radius: 4px;
  transition: all 0.2s;
}

.menu-items a:hover,
.menu-items span:hover,
.menu-items button:hover {
  background-color: #3d73aa;
  color: white;
  transition: all 0.2s;
}

.menu-item-active > a {
  background-color: #3d73aa;
  color: white;
}

.menu-item-active > span {
  background-color: #3d73aa;
  color: white;
}

.menu-item-active > button {
  background-color: #3d73aa;
  color: white;
}

.arrow::after {
  content: "";
  display: inline-block;
  margin-left: 0.28em;
  vertical-align: 0.09em;
  border-top: 0.42em solid;
  border-right: 0.32em solid transparent;
  border-left: 0.32em solid transparent;
}

.dropdown {
  position: absolute;
  right: auto;
  left: 0;
  box-shadow: 0 10px 15px -3px rgba(46, 41, 51, 0.08),
    0 4px 6px -2px rgba(71, 63, 79, 0.16);
  font-size: 0.875rem;
  z-index: 9999;
  min-width: 10rem;
  padding: 0.5rem 0;
  list-style: none;
  background-color: #fff;
  border-radius: 0.5rem;
  display: none;
}

.dropdown.show {
  display: block;
}

.dropdown .dropdown-submenu {
  position: absolute;
  left: 100%;
  top: -7px;
}

@media only screen and (max-width: 1250px) {
  .menu-items {
    font-size: 14px;
    font-weight: 600;
  }

  .menu-items > a,
  .menu-items button {
    font-size: 16px;
    padding: 10px 10px 5px;
  }
}
