.LmoqPopup-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.2);
  z-index: 100000;
}

.LmoqPopup {
  position: relative;
  max-width: 500px;
  width: 35%;
  max-height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: white;
  border-radius: 8px;
  padding: 20px 40px;
  z-index: 25;
}

.LmoqPopup__title {
  font-size: 2rem;
  line-height: 2.5rem;
  text-align: center;
  font-weight: 900;
  color: rgb(20, 20, 20);
  margin: 0 0 20px;
  padding: 25px 0 10px;
  border-bottom: 1px solid rgb(230, 230, 230);
}

.LmoqPopup__text {
  font-size: 1.5rem;
  color: rgb(20, 20, 20);
  text-align: center;
}

.LmoqPopup__button {
  font-size: 1.2rem;
  font-weight: 600;
  background-color: #3d73aa;
  color: white;
  border: none;
  border-radius: 10px;
  line-height: 1;
  padding: 10px 20px;
  cursor: pointer;
  transition: all 0.3s;
  margin: 25px auto 15px;
}

.LmoqPopup__button:hover {
  color: white;
  background-color: #333333;
}

@media only screen and (max-width: 960px) {
  .LmoqPopup {
    width: 70%;
    padding: 30px;
  }
}

@media only screen and (max-width: 500px) {
  .LmoqPopup {
    width: 80%;
    padding: 10px 15px;
  }

  .LmoqPopup__title {
    font-size: 2rem;
    padding: 0 0 10px;
    margin: 20px 0;
  }
}
