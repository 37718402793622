.navbar {
  position: sticky;
  top: 0;
  right: 0;
  left: 0;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
  padding: 25px 80px;
  z-index: 50;
  user-select: none;
}

.logo-container {
  display: flex;
  align-items: center;
  cursor: pointer;
  border: none;
  background: transparent;
  user-select: none;
}

.logo {
  width: 150px;
  user-select: none;
}

.menus {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  list-style: none;
}

.nav-button {
  font-size: 1rem;
  background-color: #3d73aa;
  padding: 12px 17px;
  color: white;
  text-transform: uppercase;
  border: none;
  border-radius: 10px;
  line-height: 1;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  cursor: pointer;
  transition: all 0.3s;
}

.nav-button:hover {
  background-color: #333333;
}

.hamburger {
  width: 25px;
  height: 2px;
  background-color: rgb(40, 40, 40);
  position: relative;
  display: none;
  cursor: pointer;
}

.hamburger::before {
  content: "";
  position: absolute;
  top: 8px;
  width: 25px;
  height: 2px;
  background-color: rgb(40, 40, 40);
}

.hamburger::after {
  content: "";
  position: absolute;
  bottom: 8px;
  width: 25px;
  height: 2px;
  background-color: rgb(40, 40, 40);
}

.hamburger1 {
  width: 25px;
  height: 2px;
  background-color: rgb(40, 40, 40);
  position: relative;
  display: none;
  cursor: pointer;
}

.hamburger1::before {
  content: "";
  position: absolute;
  transform: rotate(45deg);
  width: 25px;
  height: 2px;
  background-color: rgb(40, 40, 40);
  transition: ease-in 0.3s;
}

.hamburger1::after {
  content: "";
  position: absolute;
  transform: rotate(-45deg);
  width: 25px;
  height: 2px;
  background-color: rgb(40, 40, 40);
  transition: ease-in 0.3s;
}

@media only screen and (max-width: 1250px) {
  .navbar {
    padding: 20px;
  }

  .logo {
    width: 120px;
  }

  .nav-button {
    font-size: 14px;
    padding: 10px 15px;
  }
}

@media only screen and (max-width: 960px) {
  .navbar {
    padding: 10px 20px;
  }

  .logo-container {
    margin: 0 auto;
  }

  .logo {
    max-width: 100%;
    width: 120px;
  }

  .hamburger {
    display: block;
  }
  .hamburger1 {
    display: block;
    background-color: transparent;
  }

  .nav-button {
    font-size: 14px;
    padding: 10px 10px;
    margin-left: -90px;
  }

  .menus {
    position: absolute;
    top: 60px;
    width: 98%;
    background-color: rgba(255, 255, 255, 0.9);
    display: flex;
    flex-direction: column;
    padding: 10px;
    border-radius: 0 0 20px 20px;
    border-top: 1px solid rgba(120, 120, 120, 0.3);
    transition: all 0.5s;
  }
}

@media only screen and (max-width: 500px) {
  .navbar {
    height: 30px;
    padding: 10px;
  }

  .logo {
    max-width: 100%;
    width: 100px;
  }

  .nav-button {
    font-size: 10px;
    padding: 10px;
    margin-left: -50px;
  }

  .menus {
    top: 50px;
    width: 95%;
  }
}
