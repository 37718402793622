@keyframes slide {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}

.Trusted {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 0 auto;
  padding: 40px 0;
  overflow: hidden;
}

.Trusted_title {
  font-size: 2.5rem;
  font-weight: 600;
  text-align: center;
  margin: 0;
  padding: 0;
}

.Trusted_slider-container {
  white-space: nowrap;
  padding: 20px 0 40px;
  overflow: hidden;
  position: relative;
}

.Trusted_slider-container::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 250px;
  height: 100%;
  background: linear-gradient(to left, rgba(255, 255, 255, 0), white);
  content: "";
  z-index: 2;
}

.Trusted_slider-container::after {
  position: absolute;
  top: 0;
  right: 0;
  width: 250px;
  height: 100%;
  background: linear-gradient(to right, rgba(255, 255, 255, 0), white);
  content: "";
  z-index: 2;
}

.Trusted_slider-container:hover .Trusted_slider {
  animation-play-state: paused;
}

.Trusted_slider {
  display: inline-block;
  animation: 20s slide infinite linear;
}

.Trusted_image {
  height: 100px;
  margin: 0 40px;
}

@media only screen and (max-width: 500px) {
  .Trusted {
    width: 100%;
    padding: 20px 0;
  }

  .Trusted_title {
    font-size: 2rem;
  }

  .Trusted_image {
    height: 70px;
    margin: 0 25px;
  }

  .Trusted_slider-container::before {
    width: 80px;
  }

  .Trusted_slider-container::after {
    width: 80px;
  }
}
