.Blog {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
}

.Blog_header {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 1)
    ),
    url("../../images/blog/faq2.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  margin: 0;
  padding: 100px 0;
}

.Blog_header-title {
  font-size: 2.5rem;
  font-weight: 600;
  padding: 0;
}

.Blog_header-subtitle {
  max-width: 80%;
  text-align: center;
  font-size: 1.4rem;
  margin: -20px 0 0;
  padding: 0;
}

.Blog_header-text {
  width: 80%;
  font-size: 1.1rem;
  line-height: 1.6;
  font-weight: 500;
  color: black;
  text-align: center;
  margin: 0 0 50px;
}

.Blog_body {
  width: 80%;
  display: flex;
  flex-direction: column;
  gap: 50px;
  margin: 0 auto 100px;
}

.Blog-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
}

.Blog_image {
  width: 35%;
  cursor: pointer;
}

.Blog_content-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.Blog_content-title {
  font-size: 1.5rem;
  font-weight: 600;
  cursor: pointer;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.Blog_content-title:hover {
  text-decoration: underline;
}

.Blog_content-span {
  overflow: hidden;
  font-size: 1rem;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.Blog_button {
  width: 25%;
  text-transform: capitalize;
  cursor: pointer;
  padding: 5px 0;
}

@media only screen and (max-width: 760px) {
  .Blog_header {
    background-image: linear-gradient(
        rgba(255, 255, 255, 0.6),
        rgba(255, 255, 255, 1)
      ),
      url("../../images/blog/faq2.png");
    padding: 50px 0;
  }

  .Blog_header-subtitle {
    margin: -15px 0 0;
  }

  .Blog_header-text {
    text-align: left;
    width: 95%;
    margin: 0 0 50px;
  }

  .Blog_body {
    width: 95%;
    gap: 50px;
    margin: 0 auto 50px;
  }

  .Blog-container {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }

  .Blog_image {
    width: 60%;
  }

  .Blog_content-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 10px;
  }

  .Blog_button {
    width: 40%;
  }
}
