.Footer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  gap: 20px;
  background-color: #303641;
  color: white;
  padding: 50px 200px;
}

.Footer_div {
  max-width: 250px;
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.Footer_logo-container {
  display: flex;
  align-items: center;
  cursor: pointer;
  border: none;
  background: transparent;
  user-select: none;
}

.Footer_logo {
  width: 180px;
  margin: 0;
  padding: 0;
}

.Footer_logo-title-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Footer_link {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: white;
  font-size: 1rem;
  gap: 10px;
  margin: 0;
  padding: 0;
}

.Footer_icon {
  width: 2rem;
}

.Footer_title {
  font-size: 1.5rem;
  font-weight: 600;
  color: white;
}

.Footer_subtitle {
  font-size: 1rem;
  color: white;
  margin: 0;
  padding: 0;
  cursor: pointer;
}

.Footer_subtitle:hover {
  text-decoration: underline;
}

.Footer_nav-container {
  display: flex;
  color: white;
}

.Footer_nav {
  font-size: 1rem;
  color: white;
  border-right: 1px solid rgb(220, 220, 220);
  padding: 0 5px;
  cursor: pointer;
}

.Footer_nav:last-of-type {
  border: none;
}

.Footer_nav:hover {
  text-decoration: underline;
}

.Footer__list-image {
  width: 30px;
  margin: 0 10px;
  transition: all 0.2s;
}

.Footer__list-image:hover {
  margin-bottom: 10px;
}

.Footer_rights {
  background-color: #262626;
  padding: 20px;
  transition: all 0.3s;
}

.footer__rights-text {
  font-family: "Raleway", sans-serif;
  font-size: 0.9rem;
  font-weight: 900;
  color: rgb(200, 200, 200);
}

@media only screen and (max-width: 1250px) {
  .Footer {
    justify-content: space-between;
    gap: 30px;
    padding: 100px;
  }

  .Footer_logo {
    width: 180px;
  }

  .Footer_rights {
    font-size: 12px;
    padding: 15px;
  }
}

@media only screen and (max-width: 960px) {
  .Footer {
    justify-content: space-between;
    gap: 30px;
    padding: 50px;
  }

  .Footer_logo {
    width: 150px;
  }

  .Footer_link {
    font-size: 0.8rem;
    gap: 10px;
  }

  .Footer_icon {
    width: 20px;
  }

  .Footer_title {
    font-size: 1.2rem;
  }

  .Footer_subtitle {
    font-size: 0.8rem;
  }

  .footer__rights-text {
    font-size: 1rem;
  }
}

@media only screen and (max-width: 550px) {
  .Footer {
    flex-direction: column;
    gap: 30px;
    padding: 20px 50px;
  }

  .Footer_div {
    max-width: 400px;
    gap: 10px;
  }

  .Footer_logo {
    width: 150px;
  }

  .Footer_link {
    font-size: 1.2rem;
    gap: 10px;
  }

  .Footer_icon {
    width: 25px;
  }

  .Footer_title {
    border-bottom: 1px solid grey;
  }

  .Footer_subtitle {
    font-size: 1.1rem;
  }
}
