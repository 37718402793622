.Services {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Services_header {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.4),
      rgba(255, 255, 255, 1)
    ),
    url("../../images/services/services.jpg");
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
  margin: 0;
  padding: 100px 0;
}

.Services_header-title {
  font-size: 2.5rem;
  font-weight: 600;
  padding: 0;
}

.Services_body {
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto 30px;
}

.Services_body-title {
  font-size: 1.8rem;
  font-weight: 600;
  text-align: center;
  margin: 40px 0 20px;
  padding: 0;
}

.Services_body-span {
  font-size: 1.1rem;
  line-height: 1.5rem;
  font-weight: 600;
  text-align: center;
  margin: 30px 0;
}

.Services_body-subtitle {
  font-size: 1.5rem;
  font-weight: 600;
  text-align: center;
  margin-top: 40px;
}

.Services_body-category-title {
  font-size: 1.3rem;
  font-weight: 600;
  margin: 30px 0 0;
}

.Services_body-category-text {
  max-width: 90%;
  font-size: 1rem;
  line-height: 1.3rem;
  margin: 5px 0 0;
}

.Services_body-image-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 40px auto 0;
  padding: 0;
}

.Services_body-image {
  width: 60%;
  margin: 0;
  padding: 0;
}

.Services_p {
  font-size: 1.2rem;
  line-height: 1.6;
  font-weight: 500;
  color: black;
  margin: 20px 0;
  text-align: center;
}

@media only screen and (max-width: 500px) {
  .Services_header {
    padding: 50px 0;
  }

  .Services_body {
    width: 95%;
    margin: 20px auto;
  }

  .Services_body-image {
    width: 100%;
  }
}
