.popup {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 100000;
}

.popup__container {
  position: relative;
  max-width: 400px;
  width: 35%;
  max-height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #444c5c;
  border-radius: 8px 8px 0 0;
  padding: 20px 40px;
  z-index: 25;
}

.popup__close {
  position: absolute;
  right: 15px;
  top: 15px;
  width: 20px;
  align-self: flex-end;
  cursor: pointer;
}

.popup_success {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 25px 0 0;
}

.popup__title {
  font-size: 2rem;
  line-height: 1;
  text-align: center;
  font-weight: 600;
  color: rgb(230, 230, 230);
  margin: 0;
  padding: 0 0 10px;
  border-bottom: 1px solid rgb(230, 230, 230);
}

.popup__header {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 20px;
  margin: 15px 0;
}

.popup__image {
  width: 130px;
}

.popup__text {
  font-size: 1rem;
  color: rgb(230, 230, 230);
  text-align: center;
}

.popup__form-input {
  background-color: rgb(230, 230, 230);
  border: none;
  border-radius: 3px;
  font-size: 1rem;
  margin: 0 0 10px;
  padding: 10px;
}

.popup__form-input:focus {
  outline: none;
}

.popup__form-input-text {
  height: 60px;
  background-color: rgb(230, 230, 230);
  border: none;
  font-size: 1rem;
  line-height: 1.5rem;
  border-radius: 3px;
  margin: 0;
  padding: 10px;
  resize: none;
  outline: none;
}

.popup__form-error {
  color: white;
  margin: 15px 0 0;
  text-align: center;
}

.popup__form-button {
  text-align: center;
  align-self: center;
  font-weight: 600;
  line-height: 1;
  font-size: 1rem;
  background-color: white;
  color: #333333;
  margin: 10px 0 0;
  padding: 10px 30px;
  border-radius: 5px;
  cursor: pointer;
  transition: ease-in-out 0.2s;
  text-align: center;
}

.popup__form-button:hover {
  background-color: #212529;
  color: white;
  border: 2px solid #212529;
}

.popup__form-button-loading {
  background-color: #333333;
  color: whitesmoke;
  padding: 10px 20px 10px 30px;
}

.popup__footer {
  max-width: 400px;
  width: 35%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgb(230, 230, 230);
  border-radius: 0 0 8px 8px;
  padding: 0 40px;
}

.popup__footer-list {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 25px;
  padding: 5px 0 0;
  border-bottom: 1px solid #233f5b79;
}

.popup__footer-link {
  font-size: 14px;
}

.popup__footer-link:hover {
  text-decoration: underline;
}

.popup__footer-icon {
  width: 20px;
  margin: 0;
  padding: 0;
}

.popup__footer-text {
  font-size: 0.8rem;
  font-weight: 600;
  line-height: 0;
  color: rgb(138, 138, 138);
  margin: 18px 0;
  cursor: default;
}

.popup__subtitle {
  font-size: 1.5rem;
  color: white;
  margin: 25px auto 20px;
}

.popup__subtext {
  font-size: 1.1rem;
  color: white;
  margin: 0 auto 25px;
  text-align: center;
}

@media only screen and (max-width: 960px) {
  .popup__container {
    width: 70%;
    padding: 30px;
  }

  .popup__footer {
    max-width: 450px;
    width: 70%;
    padding: 0 30px;
  }
}

@media only screen and (max-width: 500px) {
  .popup__container {
    width: 80%;
    padding: 10px 15px;
  }

  .popup__footer {
    max-width: 450px;
    width: 80%;
    padding: 0 15px;
  }

  .popup__title {
    font-size: 2rem;
    padding: 0 0 10px;
    margin: 20px 0;
  }

  .popup__form-input {
    margin: 0 0 10px;
  }

  .popup__header {
    width: 100%;
    gap: 15px;
    margin: 0 0 15px;
  }

  .popup__image {
    width: 70px;
  }

  .popup__footer-text {
    font-size: 0.8rem;
    margin: 10px 0;
  }

  .popup__form-button {
    font-size: 1rem;
    margin: 20px auto 10px;
    padding: 10px 20px;
  }
}
