.Article {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Article_header {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 50px 0;
}

.Article_header-title {
  text-align: center;
  font-size: 2rem;
  font-weight: 600;
  margin: 30px 0;
  padding: 0;
}

.Article_header-image {
  width: 80%;
  max-width: 800px;
}

.Article_body {
  width: 80%;
  display: flex;
  flex-direction: column;
  gap: 25px;
  margin: 0 auto 100px;
}

.Article_body-title {
  font-size: 1.3rem;
  font-weight: 600;
  text-decoration: underline;
  margin: 40px 0 0;
  padding: 0;
}

.Article_body-text {
  font-size: 1rem;
  line-height: 1.4rem;
  margin: 0;
  padding: 0;
}

.Article_More {
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #3d74aa77;
  padding: 50px;
}

.Article_More-title {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 50px;
}

.Article_Blog-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  margin-bottom: 50px;
}

.Article_Blog_image {
  max-width: 30%;
  cursor: pointer;
}

.Article_Blog_content-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.Article_Blog_content-title {
  font-size: 1.3rem;
  font-weight: 600;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.Article_Blog_content-title:hover {
  text-decoration: underline;
}

.Article_Blog_content-span {
  overflow: hidden;
  font-size: 1rem;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.Article_Blog_button {
  width: 20%;
  text-transform: capitalize;
  cursor: pointer;
  padding: 3px 0;
}

@media only screen and (max-width: 500px) {
  .Article_header {
    margin: 20px 0;
  }

  .Article_body {
    width: 90%;
    gap: 20px;
    margin: 0 auto 50px;
  }

  .Article_Blog-container {
    flex-direction: column-reverse;
    justify-content: left;
    gap: 10px;
    margin-bottom: 50px;
  }

  .Article_Blog_image {
    max-width: 100%;
  }

  .Article_Blog_button {
    width: 40%;
    align-self: center;
  }
}
