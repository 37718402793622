.Products_items {
  width: 100%;
}

.Products_items-container {
  width: 100%;
  position: relative;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-template-rows: auto;
  gap: 20px;
  justify-content: center;
}

.Products_loader {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Item_navlink:hover {
  text-decoration: underline;
}

.Item {
  max-width: 200px;
  max-height: 300px;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 5px;
  border-radius: 5px;
  overflow: hidden;
  background-color: rgba(245, 245, 248, 0.2);
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 5px 5px 15px 1px rgba(0, 0, 0, 0.4);
  -webkit-box-shadow: 5px 5px 15px 1px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 5px 5px 15px 1px rgba(0, 0, 0, 0.4);
  margin: 0;
  padding: 10px;
}

.Item_image {
  width: 100%;
  cursor: pointer;
}

.Item_title {
  max-width: 220px;
  overflow: hidden;
  font-size: 1rem;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin: 10px 0;
  cursor: pointer;
  transition: all 0.3s;
}

.Item_title:hover {
  text-decoration: underline;
}

.Item_subtitle {
  font-size: 1rem;
  font-weight: 900;
  margin: 0;
  padding: 0;
}

.Products_Error {
  text-align: center;
  font-size: 1.5rem;
  padding: 20px 0 0;
}

@media only screen and (max-width: 960px) {
  .Products_items-container {
    width: 100%;
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
    gap: 10px;
  }
}

@media only screen and (max-width: 760px) {
  .Products_items-container {
    width: 100%;
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
    gap: 10px;
  }
}

@media only screen and (max-width: 600px) {
  .Products_items-container {
    width: 100%;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 10px;
  }

  .Item {
    border: 1px solid rgba(0, 0, 0, 0.3);
    box-shadow: 3px 3px 10px 1px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 3px 3px 10px 1px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 3px 3px 10px 1px rgba(0, 0, 0, 0.3);
  }
}
